import { LOCAL_STORAGE, TRADE_TYPE_SPOT } from '../constants';

// 系统临时数据

/**
 * 系统配置
 */
export function setConfig(data) {
  window.localStorage[LOCAL_STORAGE.CONFIG] = JSON.stringify(data || {});
}

/**
 * 系统配置
 */
export function getConfig() {
  if (window.localStorage[LOCAL_STORAGE.CONFIG]) {
    return JSON.parse(window.localStorage[LOCAL_STORAGE.CONFIG]);
  }
  
  return null;
}

/**
 * 当前选中的币种
 */
export function getSymbol() {
  return window.localStorage[LOCAL_STORAGE.SYMBOL] || 'BTC';
}

/**
 * 当前选中的币种
 */
 export function setSymbol(symbol) {
  window.localStorage[LOCAL_STORAGE.SYMBOL] = symbol || '';
}

/**
 * 当前选中的交易类型
 */
export function getTradeType() {
  return window.localStorage[LOCAL_STORAGE.TRADE_TYPE] || TRADE_TYPE_SPOT;
}

/**
 * 当前选中的交易类型
 */
 export function setTradeType(tradeType) {
  window.localStorage[LOCAL_STORAGE.TRADE_TYPE] = tradeType || '';
}
