/**
 * 资产接口
 */
export default SERVER => ({
  
  /**
   * 现货资产
   * method GET
   */
  SPOT_ASSET: `${SERVER}/api/asset/coins`,

  /**
   * 期权资产
   * method GET
   */
  OPTIONS_ASSET: `${SERVER}/api/asset/options`,

  /**
   * 合约资产
   * method GET
   */
  CONTRACT_ASSET: `${SERVER}/api/asset/contract`,

  /**
   * 资产划转
   * method POST
   */
  ASSET_ACCOUNT_TRANSFER: `${SERVER}/api/asset/transfer`,

  /**
   * 提现配置
   * method GET
   */
  WITHDRAW_CONFIG: `${SERVER}/api/config/despoit`,
  
  /**
   * 提现
   * method POST
   */
  ASSET_WITHDRAW: `${SERVER}/api/asset/despoit`,

  /**
   * 提现记录
   * method GET
   */
  ASSET_WITHDRAW_RECORD_LIST: `${SERVER}/api/asset/despoit/list`,

  /**
   * 取消提现订单
   * method POST
   */
  ASSET_CANCEL_WITHDRAW: `${SERVER}/api/asset/despoit/cancel`,

  /**
   * 现货流水
   * method GET
   */
  ASSET_SPOT_RECORD_LIST: `${SERVER}/api/asset/coin/flows`,

  /**
   * 币币划转
   * method POST
   */
  ASSET_COIN_EXCHANGE: `${SERVER}/api/asset/exchange`,
  
});
