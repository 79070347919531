import { LOCAL_STORAGE } from '../constants';

/**
 * getToken
 */
export function getToken() {
  return window.localStorage[LOCAL_STORAGE.ACCESS_TOKEN_PROBIT] || '';
}

/**
 * setToken
 * @param {*} value
 */
export function setToken(value) {
  window.localStorage[LOCAL_STORAGE.ACCESS_TOKEN_PROBIT] = value;
}

/**
 * 是否登录
 */
export function isSignIn() {
  const token = getToken();
  if (token && token.length > 0) {
    return true;
  }

  return false;
}

/**
 * getUser
 */
export function getUser() {
  if (window.localStorage[LOCAL_STORAGE.USER_INFO]) {
    return JSON.parse(window.localStorage[LOCAL_STORAGE.USER_INFO]);
  }

  return {};
}

/**
 * setUser
 * @param {*} value
 */
export function setUser(value) {
  window.localStorage[LOCAL_STORAGE.USER_INFO] = JSON.stringify(value || {});
}

/**
 * 是否被冻结
 */
export function isUserFrozen() {
  const user = getUser();

  if (user && user.is_freeze == 1) {
    return true;
  }

  return false;
}

/**
 * 登出
 */
export function signOut() {
  setToken('');
  setUser({});
}
