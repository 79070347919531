import { PropTypes } from 'prop-types';
import React, { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';

import AppLayout from '../layout';
import AsyncComponent from '../routes';
import LoadingPlaceholder from '../components/LoadingPlaceholder';

const AppContainer = ({ store, history }) => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <ConnectedRouter history={history}>
          <AppLayout>
            <Suspense fallback={<LoadingPlaceholder />}>
              <Switch location={location}>
                <Route
                  exact
                  path='/'
                  render={props => <AsyncComponent {...props} componentName='Market' />}
                />

                <Route
                  exact
                  path='/trade'
                  render={props => <AsyncComponent {...props} componentName='Trade' />}
                />

                <Route
                  exact
                  path='/signin'
                  render={props => <AsyncComponent {...props} componentName='SignIn' />}
                />
                <Route
                  exact
                  path='/signup'
                  render={props => <AsyncComponent {...props} componentName='SignUp' />}
                />
                <Route
                  exact
                  path='/forgetpwd'
                  render={props => <AsyncComponent {...props} componentName='ForgetPwd' />}
                />
                <Route
                  path='/invite/:code'
                  render={props => <AsyncComponent {...props} componentName='Invite' />}
                />

                <Route
                  exact
                  path='/asset'
                  render={props => <AsyncComponent {...props} componentName='Asset' />}
                />

                <Route
                  exact
                  path='/user-center'
                  render={props => <AsyncComponent {...props} componentName='UserCenter' />}
                />
                <Route
                  exact
                  path='/user-center/team'
                  render={props => <AsyncComponent {...props} componentName='MyTeam' />}
                />
                <Route
                  exact
                  path='/user-center/share'
                  render={props => <AsyncComponent {...props} componentName='Share' />}
                />
                <Route
                  exact
                  path='/user-center/kyc'
                  render={props => <AsyncComponent {...props} componentName='Kyc' />}
                />
                
                <Route
                  exact
                  path='/loan'
                  render={props => <AsyncComponent {...props} componentName='Loan' />}
                />
                <Route
                  exact
                  path='/loan/add'
                  render={props => <AsyncComponent {...props} componentName='LoanAdd' />}
                />

                <Route
                  exact
                  path='/help-center/aboutus'
                  render={props => <AsyncComponent {...props} componentName='AboutUs' />}
                />
                <Route
                  exact
                  path='/help-center/guide/btc'
                  render={props => <AsyncComponent {...props} componentName='GuideBtc' />}
                />
                
                <Route
                  exact
                  path='/no/access'
                  render={props => <AsyncComponent {...props} componentName='NoAccess' />}
                />

                <Route
                  render={props => <AsyncComponent {...props} componentName='PageNotFound' />}
                />
              </Switch>
            </Suspense>
          </AppLayout>
        </ConnectedRouter>
      </BrowserRouter>
    </Provider>
  );
};

AppContainer.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default AppContainer;
