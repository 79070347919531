export default {
  AVATAR_DEFAULT: require('./avatar-default.png'),
  LOGO: require('./logo.png'),
  CUSTOMER: require('./customer.png'),
  
  TRIANGLE_UP: require('./triangle-up.png'),
  TRIANGLE_DOWN: require('./triangle-down.png'),

  ICON_LANGUAGE: require('./icon-language.png'),
  ICON_LANGUAGE_HOVER: require('./icon-language-hover.png'),

  TRADE_SPOT: require('./trade-spot.svg'),
  TRADE_OPTIONS: require('./trade-options.svg'),
  TRADE_CONTRACT: require('./trade-contract.svg'),

  CARET_DOWN: require('./caret-down.png'),
  CARET_UP: require('./caret-up.png'),

  FAVORITES: require('./favorites.png'),
  FAVORITES_ACTIVE: require('./favorites-active.png'),
  FAVORITES_YELLOW: require('./favorites-yellow.png'),

  ICON_PROFIT: require('./icon-profit.png'),
  ICON_LOSS: require('./icon-loss.png'),

  ICON_ASSET_SPOT: require('./icon-asset-spot.png'),
  ICON_ASSET_OPTIONS: require('./icon-asset-options.png'),
  ICON_ASSET_CONTRACT: require('./icon-asset-contract.png'),

  TRANSFER: require('./transfer.png'),

  USER_CENTER_KYC: require('./user-center-kyc.png'),
  USER_CENTER_TEAM: require('./user-center-team.png'),
  USER_CENTER_PWD: require('./user-center-pwd.png'),
  USER_CENTER_TRADE_PWD: require('./user-center-trade-pwd.png'),
  USER_CENTER_CUSTOMER_SERVICE: require('./user-center-customer-service.png'),

  USER_CENTER_STATUS_SUCCESS: require('./user-center-status-success.png'),
  USER_CENTER_STATUS_FAIL: require('./user-center-status-fail.png'),

  QUESTION_MARK: require('./question-mark.png'),

  TEAM_HALF_CIRCLE_LEFT: require('./team_half_circle_left.png'),
  TEAM_HALF_CIRCLE_RIGHT: require('./team_half_circle_right.png'),

  APPLE: require('./apple.svg'),
  ANDROID: require('./android.svg'),

  LANGUAGE: require('./language.png'),
  MORE_BLUE: require('./more-blue.png'),

  COIN_BTC: require('./coin/coin-btc.svg'),
  COIN_ETH: require('./coin/coin-eth.svg'),
  COIN_XRP: require('./coin/coin-xrp.svg'),
  COIN_LTC: require('./coin/coin-ltc.svg'),
  COIN_MATIC: require('./coin/coin-matic.svg'),
  COIN_SOL: require('./coin/coin-sol.svg'),
  COIN_DOGE: require('./coin/coin-doge.svg'),
  COIN_XLM: require('./coin/coin-xlm.svg'),
  COIN_SUI: require('./coin/coin-sui.svg'),
  COIN_TRX: require('./coin/coin-trx.svg'),
  COIN_ADA: require('./coin/coin-ada.svg'),
  COIN_SHIB: require('./coin/coin-shib.svg'),
  COIN_RUNE: require('./coin/coin-rune.svg'),
  COIN_BCH: require('./coin/coin-bch.svg'),
  COIN_ARB: require('./coin/coin-arb.svg'),
  COIN_LINK: require('./coin/coin-link.svg'),
  COIN_EOS: require('./coin/coin-eos.svg'),
  COIN_ATOM: require('./coin/coin-atom.svg'),
  COIN_DOT: require('./coin/coin-dot.svg'),
  COIN_UNI: require('./coin/coin-uni.svg'),
  COIN_USDT: require('./coin/coin-usdt.svg'),
  COIN_PROBIT: require('./coin/coin-probit.png'),

  NAV_BACK: require('./nav-back.png'),
  NAV_ADD: require('./nav-add.png'),
  M_LANGUAGE: require('./m-language.png'),
  M_MORE: require('./m-more.png'),
  
  TAB_HOME_SEL: require('./tab-home-sel.png'),
  TAB_HOME: require('./tab-home.png'),
  TAB_TRADE_SEL: require('./tab-trade-sel.png'),
  TAB_TRADE: require('./tab-trade.png'),
  TAB_ASSET_SEL: require('./tab-asset-sel.png'),
  TAB_ASSET: require('./tab-asset.png'),
  TAB_USER_CENTER_SEL: require('./tab-user-center-sel.png'),
  TAB_USER_CENTER: require('./tab-user-center.png'),
  
  HOME_LOAN: require('./home-loan.png'),
  HOME_MARKET: require('./home-market.png'),
  HOME_OPTIONS: require('./home-options.png'),
  HOME_SPOT: require('./home-spot.png'),
};
