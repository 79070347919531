import { getLanguageCode } from './language';
import { SERVER_PRODUCTION } from '../middleware/apis/index';
import { getUser } from '../utils/user';

// 隐私政策
export function getPrivacyUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/privacy.html`;
}

// 用户协议
export function getTermsUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/terms.html`;
}

// Cookie 政策
export function getCookieUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/cookie.html`;
}

// 反洗钱协议
export function getAmlUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/aml.html`;
}

// 行为准则
export function getConductUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/conduct.html`;
}

// 邀请链接
export function getShareUrl() {
  return `${SERVER_PRODUCTION}/invite/${getUser().invite_code}`;
}

// 贷款协议
export function getLoanAgreementUrl() {
  return `${SERVER_PRODUCTION}/docs/${getLanguageCode()}/loan-agreement.html`;
}

// 下载链接
export function getAndroidDownloadUrl() {
  return '';
}
export function getIosDownloadUrl() {
  // return 'itms-apps://itunes.apple.com/app/id1530171035';
  return '';
}

// 关于我们
export function aboutUsUrl() {
  return '/help-center/aboutus';
}

// 官方声明
export function annoucementsUrl() {
  return '';
}

// FAQ
export function FAQUrl() {
  return '';
}
// 充提币状态
export function depositWithdrawStatus() {
  return '';
}
// 官方验证
export function officialVerification() {
  return '';
}
